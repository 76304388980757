.container {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;

  .ring {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 5px;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    animation: ringAnimation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #30e790 transparent transparent transparent;

    :nth-child(1) {
      animation-delay: -0.45s;
    }

    :nth-child(2) {
      animation-delay: -0.3s;
    }

    :nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  &.white {
    .ring {
      border-color: #fff transparent transparent transparent;
    }
  }

  &.black {
    .ring {
      border-color: #000 transparent transparent transparent;
    }
  }

  &.danger {
    .ring {
      border-color: red transparent transparent transparent;
    }
  }

  &.gray {
    .ring {
      border-color: #e6eaf0 transparent transparent transparent;
    }
  }

  &.small {
    width: 25px;
    height: 25px;

    .ring {
      border-width: 1px;
      width: 15px;
      height: 15px;
    }
  }
}

@keyframes ringAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
