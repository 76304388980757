.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 410px;
  height: 100%;
  margin: 0 auto;

  .title {
    font-size: 20px;
    color: '#000';
    margin: 10px 20px;
    line-height: 24px;
  }

  .input {
    padding: 21px 12px;
  }

  .continueButtonContainer {
    display: block;
  }

  .continueButton {
    width: 100%;
    margin-top: 15px;
  }

  .errorMessage {
    width: 100%;
    margin-top: 10px;
    padding: 5px 10px;
    text-align: center;
    color: 'red';
    font-size: 14px;
    line-height: 16px;
  }
}
