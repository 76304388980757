.title {
  font-size: 36px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 25px;
}

.captionText {
  width: 80%;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin: 25px auto;
}

.profileForm {
  width: 410px;
  margin: 0 auto;
}

.firstNameInput {
  padding: 21px 12px;
}

.lastNameInput {
  padding: 21px 12px;
}

.alertName {
  margin: 18px auto 15px auto;
  font-size: 18px;
  line-height: 16px;
}

.continueButtonContainer {
  display: block;
}
