@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'montthin';
  src: url('../assets/fonts/mont-thin-webfont.woff2') format('woff2'),
    url('../assets/fonts/mont-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montsemibold';
  src: url('../assets/fonts/mont-semibold-webfont.woff2') format('woff2'),
    url('../assets/fonts/mont-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montregular';
  src: url('../assets/fonts/mont-regular-webfont.woff2') format('woff2'),
    url('../assets/fonts/mont-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montlight';
  src: url('../assets/fonts/mont-light-webfont.woff2') format('woff2'),
    url('../assets/fonts/mont-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montheavy';
  src: url('../assets/fonts/mont-heavy-webfont.woff2') format('woff2'),
    url('../assets/fonts/mont-heavy-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montextralight';
  src: url('../assets/fonts/mont-extralight-webfont.woff2') format('woff2'),
    url('../assets/fonts/mont-extralight-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montbold';
  src: url('../assets/fonts/mont-bold-webfont.woff2') format('woff2'),
    url('../assets/fonts/mont-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montblack';
  src: url('../assets/fonts/mont-black-webfont.woff2') format('woff2'),
    url('../assets/fonts/mont-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.react-datepicker__input-container input {
  @apply border-0 px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white dark:bg-gray-800 rounded text-sm font-mont-regular shadow outline-none focus:outline-none focus:ring-0 w-full;
  // @apply block w-full text-base md:text-sm bg-white border border-gray-300 rounded shadow-sm; // form-input;
}

.react-datepicker__tab-loop {
  @apply absolute;
}

.react-datepicker-popper {
  @apply absolute top-1 z-10 left-0 w-72 text-sm transform-none bg-white shadow px-3 py-2; // !important;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-800 font-medium text-center text-xs;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-50 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-400;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-200;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-blue-500;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-blue-500;
}

.react-datepicker__day--selected {
  @apply bg-blue-500 text-white;
}

.react-datepicker__day--range-start {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__day--range-end {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}
