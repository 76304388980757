.container {
  transition: 0.2s;
  overflow: hidden;
}

.text {
  font-size: 12px;
  line-height: 16px;
  color: red;
}
