.fullScreen {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.container {
  width: 100px;
  height: 100px;
  shape-rendering: auto;

  circle {
    stroke: #30e790;
  }

  &.white {
    circle {
      stroke: #fff;
    }
  }
}
